<template>
  <div class="invite">
    <Card
      class="invite__overview"
      v-if="isLoggedIn && invite && inviteEmail === user.email"
    >
      <div class="invite__title">{{ $t("pages.invite.title") }}</div>
      <div class="invite__from">
        {{ $t("pages.invite.from") }}: {{ invite.from }}
      </div>
      <div class="invite__team">
        {{ $t("pages.invite.team") }}: {{ invite.team.name }}
      </div>
      <div class="invite__description">
        {{ $t("pages.invite.description") }}
      </div>
      <Button class="invite__add-btn" @click="onAddNewUser">
        {{ $t("pages.invite.acceptBtn") }}
      </Button>

      <div class="invite__feedback" v-if="feedback">
        {{ $t("pages.invite.feedback") }}
      </div>
    </Card>

    <Card
      class="invite__overview"
      v-if="isLoggedIn && invite && inviteEmail !== user.email"
    >
      <div class="invite__title">{{ $t("pages.invite.title") }}</div>

      <div class="invite__description">
        {{ $t("pages.invite.invalidInvitation") }}
      </div>
    </Card>

    <Card class="invite__logged-out" v-if="isLoggedIn && !invite">
      <div class="invite__title">{{ $t("pages.invite.title") }}</div>

      <div class="invite__logged-out-text">
        {{ $t("pages.invite.noInvite") }}
      </div>
    </Card>

    <Card class="invite__logged-out" v-if="!isLoggedIn">
      <div class="invite__title">{{ $t("pages.invite.title") }}</div>
      <div class="invite__from" v-if="invite">
        {{ $t("pages.invite.from") }}: {{ invite.from }}
      </div>
      <div class="invite__team" v-if="invite">
        {{ $t("pages.invite.team") }}: {{ invite.team.name }}
      </div>

      <InviteLogin v-if="showLogin" :invite="invite" />
      <InviteRegister v-if="showRegister" :invite="invite" />
    </Card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Card from "@/components/Card";
import InviteLogin from "@/components/InviteLogin";
import InviteRegister from "@/components/InviteRegister";
import Button from "@/components/Button";

export default {
  components: {
    Card,
    Button,
    InviteLogin,
    InviteRegister
  },
  data: () => ({
    feedback: false,
    showRegister: false,
    showLogin: false
  }),
  computed: {
    ...mapGetters("user", {
      user: "getUserProfile"
    }),
    ...mapGetters("invites", {
      invite: "getInvite"
    }),
    isLoggedIn() {
      return this.user ? true : false;
    },
    adjustedEmail() {
      let mail = "";
      if (this.invite.loginMethod === "email") {
        mail = this.invite.email;
      } else if (this.invite.loginMethod === "microsoft") {
        mail = this.invite.email + "#ext#@samlobbyspace.onmicrosoft.com";
      }

      return mail.replace(/\.|#/g, "_");
    },
    inviteEmail() {
      if (this.invite.loginMethod === "microsoft") {
        return (
          this.invite.email.replace("@", "_") +
          "#ext#@samlobbyspace.onmicrosoft.com"
        );
      }
      return this.invite.email;
    }
  },
  watch: {
    invite() {
      if (!this.isLoggedIn) {
        this.onCheckUser();
      }
    }
  },
  created() {
    this.fetchInvite(this.$route.params.id);
  },
  methods: {
    ...mapActions("invites", ["fetchInvite", "deleteInvite", "checkUser"]),
    ...mapActions("teams", ["updateTeam", "fetchTeam"]),
    ...mapActions("user", ["updateProfile"]),
    goToLogin() {
      this.$router.push({
        name: "Login"
      });
    },
    onAddNewUser() {
      if (this.user.email === this.inviteEmail) {
        this.fetchTeam(this.invite.team.id).then(res => {
          let team = res;
          delete team.invitedUsers[this.adjustedEmail];

          team.users[this.user.user_id] = {
            name: this.user.name,
            email: this.invite.email,
            role: this.invite.role,
            id: this.user.user_id
          };

          this.updateTeam(team);

          let teams = this.user.teams;
          let newTeam = {
            name: team.name,
            id: team.id
          };

          if (team.avatar) {
            newTeam.avatar = team.avatar;
          }

          teams.push(newTeam);

          this.updateProfile({
            ...this.user,
            currentTeam: this.invite.team.id,
            teams: teams
          }).then(() => {
            this.deleteInvite(this.invite.id).then(() => {
              this.$router.push({
                name: "Dashboard"
              });
            });
          });
        });
      } else {
        this.feedback = true;
      }
    },
    onCheckUser() {
      this.checkUser(this.inviteEmail).then(res => {
        if (res) {
          this.showLogin = true;
        } else {
          this.showRegister = true;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.invite {
  min-height: 100vh;
  @apply p-4;

  &__overview,
  &__logged-out {
    @apply w-full;
  }

  &__title {
    font-size: 28px;
    font-weight: 600;
  }

  &__description,
  &__logged-out-text {
    @apply text-ls-gray-800 my-2;
  }

  &__form {
    @apply flex flex-col mt-4;
  }

  &__label {
    margin-bottom: 4px;
  }

  &__input {
    height: 42px;
    @apply rounded-lg px-2 bg-ls-gray-100 mb-4;
  }

  &__add-btn,
  &__login-btn,
  &__register-btn {
    @apply w-full;
  }

  &__login-btn {
    @apply mb-2;
  }

  &__feedback {
    @apply mt-2 text-ls-red text-sm;
  }
}

@media screen and (min-width: 900px) {
  .invite {
    min-height: 100vh;
    @apply flex justify-center items-center;

    &__overview,
    &__logged-out {
      width: 480px;
    }
  }
}
</style>
