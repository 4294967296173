<template>
  <div class="invite-register">
    <form
      class="invite-register__form"
      @submit.prevent
      v-if="invite.loginMethod === 'email'"
    >
      <label
        class="invite-register__label text__small--bold"
        for="invite-register__input-name"
        >{{ $t("pages.register.nameLabel") }}</label
      >
      <input
        class="invite-register__input"
        v-model.trim="name"
        type="text"
        placeholder="Max Mustermann"
        id="invite-register__input-name"
      />

      <label
        class="invite-register__label text__small--bold"
        for="invite-register__input-password"
        >{{ $t("pages.register.passwordLabel") }}</label
      >
      <input
        class="invite-register__input"
        v-model.trim="password"
        type="password"
        placeholder="******"
        id="invite-register__input-password"
      />

      <Button @click="register" class="invite-register__button">
        {{ $t("pages.invite.register") }}
      </Button>
    </form>

    <Button
      theme="secondary"
      class="invite-register__button--sso"
      @click="registerWithMicrosoft"
      v-if="invite.loginMethod === 'microsoft'"
    >
      <inline-svg
        class="register__card-sso-icon"
        :src="require(`../assets/icons/Microsoft_SSO.svg`)"
        aria-label="Microsoft Logo"
      ></inline-svg>
      {{ $t("pages.register.microsoftSSO") }}
    </Button>

    <div
      v-if="feedback !== ''"
      class="invite-register__error text__small--regular"
    >
      {{ feedback }}
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { provider } from "../firebase/init";
import { mapActions } from "vuex";
import Button from "@/components/Button";

export default {
  components: {
    Button
  },
  props: {
    invite: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      name: "",
      password: "",
      performingRequest: true,
      feedback: ""
    };
  },
  computed: {
    adjustedEmail() {
      let mail = "";
      if (this.invite.loginMethod === "email") {
        mail = this.invite.email;
      } else if (this.invite.loginMethod === "microsoft") {
        mail = this.invite.email + "#ext#@samlobbyspace.onmicrosoft.com";
      }

      return mail.replace(/\.|#/g, "_");
    }
  },
  methods: {
    ...mapActions("invites", ["deleteInvite"]),
    ...mapActions("teams", ["updateTeam", "fetchTeam"]),
    ...mapActions("user", [
      "setCurrentUser",
      "fetchUserProfile",
      "updateProfile"
    ]),
    register() {
      if (this.name && this.password) {
        this.feedback = null;
        this.load = true;

        firebase
          .auth()
          .createUserWithEmailAndPassword(this.invite.email, this.password)
          .then(cred => {
            firebase
              .database()
              .ref("users/" + cred.user.uid)
              .set({
                name: this.name,
                user_id: cred.user.uid,
                email: this.invite.email,
                language: "en"
              });
            this.setCurrentUser(cred.user);
            this.addNewUser(cred.user);
          })
          .catch(err => {
            this.feedback = err.message;
          });
      } else {
        this.feedback = this.$("pages.register.fillAllFields");
      }
    },
    registerWithMicrosoft() {
      this.feedback = null;
      this.load = true;

      firebase
        .auth()
        .signInWithPopup(provider)
        .then(user => {
          firebase
            .database()
            .ref("users/" + user.user.uid)
            .set({
              name: user.user.displayName,
              user_id: user.user.uid,
              email: user.user.email,
              language: "en",
              sso: "Microsoft"
            });
          this.setCurrentUser(user.user);
          this.addNewUser(user.user);
        })
        .catch(err => {
          this.feedback = err.message;
        });
    },
    addNewUser(user) {
      this.fetchTeam(this.invite.team.id).then(res => {
        let team = res;
        delete team.invitedUsers[this.adjustedEmail];

        team.users[user.uid] = {
          name:
            this.invite.loginMethod === "email" ? this.name : user.displayName,
          email: user.email,
          role: this.invite.role,
          id: user.uid
        };

        this.updateTeam(team).then(() => {
          let teams = [];
          let newTeam = {
            name: team.name,
            id: team.id
          };
          if (team.avatar) {
            newTeam.avatar = team.avatar;
          }

          teams.push(newTeam);

          this.updateProfile({
            currentTeam: this.invite.team.id,
            teams: teams
          }).then(() => {
            this.deleteInvite(this.invite.id).then(() => {
              this.$router.push({
                name: "Dashboard"
              });
            });
          });
        });
      });
    }
  }
};
</script>

<style lang="scss">
.invite-register {
  @apply mt-4;

  &__form {
    @apply flex flex-col;
  }

  &__label {
    margin-bottom: 4px;
  }

  &__input {
    height: 42px;
    @apply rounded-lg px-2 bg-ls-gray-100 mb-4 flex items-center;
  }

  &__button {
    @apply w-full;
  }

  &__button--sso {
    margin: 0 auto;
  }

  &__error {
    @apply text-red-600 mt-2;
  }
}
</style>
