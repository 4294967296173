<template>
  <div class="invite-login">
    <form
      class="invite-login__form"
      @submit.prevent
      v-if="invite.loginMethod === 'email'"
    >
      <label
        class="invite-login__label text__small--bold"
        for="invite-login__input-password"
        >{{ $t("pages.login.passwordLabel") }}</label
      >
      <input
        class="invite-login__input"
        v-model.trim="password"
        type="password"
        placeholder="******"
        id="invite-login__input-password"
      />

      <Button @click="login" class="invite-login__button">
        {{ $t("pages.invite.login") }}
      </Button>
    </form>

    <Button
      theme="secondary"
      class="invite-login__button--sso"
      @click="loginWithMicrosoft"
      v-if="invite.loginMethod === 'microsoft'"
    >
      <inline-svg
        class="login__card-sso-icon"
        :src="require(`../assets/icons/Microsoft_SSO.svg`)"
        aria-label="Microsoft Logo"
      ></inline-svg>
      {{ $t("pages.login.microsoftSSO") }}
    </Button>
    <div
      v-if="feedback !== ''"
      class="invite-login__error text__small--regular"
    >
      {{ feedback }}
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { provider } from "../firebase/init";
import { mapActions } from "vuex";
import Button from "@/components/Button";

export default {
  components: {
    Button
  },
  props: {
    invite: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      password: "",
      load: true,
      feedback: ""
    };
  },
  computed: {
    adjustedEmail() {
      let mail = "";
      if (this.invite.loginMethod === "email") {
        mail = this.invite.email;
      } else if (this.invite.loginMethod === "microsoft") {
        mail = this.invite.email + "#ext#@samlobbyspace.onmicrosoft.com";
      }

      return mail.replace(/\.|#/g, "_");
    }
  },
  methods: {
    ...mapActions("invites", ["deleteInvite"]),
    ...mapActions("teams", ["updateTeam", "fetchTeam"]),
    ...mapActions("user", [
      "fetchUserProfile",
      "setCurrentUser",
      "updateProfile"
    ]),
    login() {
      this.feedback = "";
      this.load = true;

      firebase
        .auth()
        .signInWithEmailAndPassword(this.invite.email, this.password)
        .then(user => {
          this.load = false;
          this.setCurrentUser(user.user);
          this.fetchUserProfile().then(user => {
            this.addNewUser(user);
          });
        })
        .catch(err => {
          this.load = false;
          this.feedback = err.message;
        });
    },
    loginWithMicrosoft() {
      this.feedback = "";
      this.load = true;
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(user => {
          this.load = false;
          this.setCurrentUser(user.user);
          this.fetchUserProfile().then(user => {
            this.addNewUser(user);
          });
          // OAuth access and id tokens can also be retrieved:
          // var accessToken = credential.accessToken;
          // var idToken = credential.idToken;
        })
        .catch(err => {
          this.load = false;
          this.feedback = err.message;
        });
    },
    addNewUser(user) {
      this.fetchTeam(this.invite.team.id).then(res => {
        let team = res;
        if (
          Object.prototype.hasOwnProperty.call(
            team.invitedUsers,
            this.adjustedMail
          )
        ) {
          delete team.invitedUsers[this.adjustedEmail];
        }

        team.users[user.user_id] = {
          name: user.name,
          email: this.invite.email,
          role: this.invite.role,
          id: user.user_id
        };
        this.updateTeam(team);

        let teams = user.teams ? user.teams : [];
        let newTeam = {
          name: team.name,
          id: team.id
        };

        if (team.avatar) {
          newTeam.avatar = team.avatar;
        }

        teams.push(newTeam);

        this.updateProfile({
          ...user,
          currentTeam: this.invite.team.id,
          teams: teams
        }).then(() => {
          this.deleteInvite(this.invite.id).then(() => {
            this.$router.push({
              name: "Dashboard"
            });
          });
        });
      });
    }
  }
};
</script>

<style lang="scss">
.invite-login {
  @apply mt-4;

  &__form {
    @apply flex flex-col;
  }

  &__label {
    margin-bottom: 4px;
  }

  &__input {
    height: 42px;
    @apply rounded-lg px-2 bg-ls-gray-100 mb-4 flex items-center;
  }

  &__button {
    @apply w-full;
  }

  &__error {
    @apply text-red-600 mt-2;
  }
}
</style>
